<template>
  <b-modal
    id="filter-patient-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title">Filtro de pacientes</div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>
    <div class="around-content">
      <b-row>
        <b-col cols="8">
          <b-form-group id="field_category">
            <validation-observer>
              <label class="label" for="field_category">Categoria</label>
              <v-autocomplete
                v-model="category"
                :options="categories"
                placeholder="Selecionar"
              />
            </validation-observer>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group id="birthday-filter">
            <validation-observer>
              <div class="label">
                <label for="birthday-filter"> Data de Nascimento </label>
              </div>
              <multiselect
                :disabled="category !== 'birthday'"
                :options="periods"
                :showLabels="false"
                track-by="label"
                label="label"
                v-model="selectedBirthdayRange"
                placeholder="Selecionar data"
                openDirection="bottom"
                class="with-border"
                :class="{ disabled: category !== 'birthday' }"
                v-show="!showDatePicker"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
              </multiselect>
              <b-form-group v-show="showDatePicker">
                <date-picker
                  range
                  ref="datepicker"
                  class="full"
                  format="DD MMM"
                  v-model="period"
                  :clearable="true"
                  :lang="langDatePicker"
                ></date-picker>
              </b-form-group>
            </validation-observer>
          </b-form-group>
        </b-col>

        <b-col cols="12" class="my-3 d-flex justify-content-end">
          <div @click="resetFilter" class="mr-3 reset__button">Redefinir</div>
          <b-button variant="primary" @click="filter"> Concluir </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'
import { PatientStatus } from '@/utils/patientStatus'
import periods from '@/utils/periods'

export default {
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    'v-autocomplete': () => import('@/components/Autocomplete'),
    ChevronDown: () => '@/assets/icons/chevron-down.svg'
  },
  data() {
    return {
      period: null,
      selectedBirthdayRange: null,
      periods,
      category: '',
      periodSelectedString: '',
      categories: [
        {
          id: 1,
          value: PatientStatus.ACTIVE,
          name: 'users-active',
          label: 'Usuários ativos'
        },
        {
          id: 3,
          value: PatientStatus.INACTIVE,
          name: 'users-inactive',
          label: 'Usuários inativos'
        },
        {
          id: 2,
          value: 'birthday',
          name: 'birthday',
          label: 'Aniversariantes'
        }
      ],
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
      custom: false
    }
  },
  computed: {
    showDatePicker() {
      return this.custom
    }
  },
  methods: {
    moment,
    clearModal() {
      this.category = ''
      this.period = null
      this.periodSelectedString = ''
      this.selectedBirthdayRange = null
      this.custom = null
    },
    closeModal() {
      this.$bvModal.hide('filter-patient-modal')
    },
    resetFilter() {
      this.clearModal()

      const filterParams = {
        periodSelectedString: '',
        patientStatus: '',
        period: null
      }

      this.$emit('filterPatients', filterParams)
    },
    filter() {
      if (this.category === 'birthday' && !this.period) {
        this.$toast.warning('Adicione uma data a ser filtrada')
        return
      }
      if (this.category !== 'birthday') {
        this.period = null
        this.periodSelectedString = ''
      }

      const filterParams = {
        patientStatus: this.category,
        period: this.period,
        periodSelectedString: this.periodSelectedString
      }

      this.$emit('filterPatients', filterParams)
      this.closeModal()
    }
  },
  watch: {
    selectedBirthdayRange() {
      if (
        !this.selectedBirthdayRange.value &&
        this.selectedBirthdayRange.label === 'Personalizado'
      ) {
        this.custom = true
        return
      }
      const value = this.selectedBirthdayRange.value
      this.periodSelectedString = `${moment(value.start).format(
        'DD MMM'
      )} ~ ${moment(value.end).format('DD MMM')}`
      this.period = [value.start, value.end]
    },
    category() {
      if (this.category !== 'birthday') {
        this.selectedBirthdayRange = null
      }
    }
  }
}
</script>

<style lang="scss">
#filter-patient-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;

      .form-group {
        label {
          font-size: 1rem;
          font-weight: 600;
        }

        .form-control {
          font-size: 1rem;
          color: var(--type-active);
        }

        .options-content,
        .select-option-container {
          font-size: 1rem;
          font-weight: 500;
          color: var(--type-active);
        }

        .disabled {
          pointer-events: none;

          .form-control {
            background: var(--neutral-100);
          }
        }
      }

      .reset__button {
        display: flex;
        cursor: pointer;
        font-weight: 600;
        align-items: center;
        color: var(--blue-500);
      }
    }
  }
}
</style>
